html {
  font-size: 16px;
}

@media only screen and (max-width: 1400px) {

  html,
  body {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #F2F3F8;
}

#root {
  height: 100%;
  background: white;
}

.ContainerWeb {
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ContainerWeb {
    width: 95%;
    max-width: 820px;
  }

  .ContainerWeb.large {
    width: 95%;
    max-width: 845px;
  }
}

@media screen and (min-width: 992px) {
  .ContainerWeb {
    width: 92%;
    max-width: 1120px;
  }

  .ContainerWeb.large {
    width: 95%;
    max-width: 1200px;
  }
}

@media screen and (min-width: 1200px) {
  .ContainerWeb {
    width: 90%;
    max-width: 1300px;
  }

  .ContainerWeb.large {
    width: 95%;
    max-width: 1400px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  height: 5px;
  ;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888856;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #88888856;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

button:hover {
  cursor: pointer;
}


.ant-input-affix-wrapper .ant-input {
  color: #0B3558;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  /* 155.556% */
  ::placeholder {
    color: #808080;
    opacity: 1;
    /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #808080;
  }
}

.ant-btn {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 5px;
}

.ant-picker.input-date-picker-filter .ant-picker-input input {
  color: #0B3558;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}

.ant-picker.input-date-picker-filter .ant-picker-input input::placeholder {
  color: #0B3558;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 1;
  /* Firefox */
}

.ant-picker.input-date-picker-filter .ant-picker-input input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #0B3558;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ant-picker.input-date-picker-filter.ant-picker-outlined {
  width: 165px;
}

.modal-calendar-date-picker .ant-modal-content {
  padding: 16px;
}

.ant-picker.ant-picker-outlined {
  border-radius: 5px;
  border: 1px solid #E7EDF6;
  background: #fff;
}

.ant-select.ant-select-outlined .ant-select-selector {
  display: flex;
  height: 36px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid #E7EDF6;
  background: #FFF;
}

.ant-select.ant-select-outlined .ant-select-selector .ant-select-selection-search-input {
  height: 100%;
}

.ant-select.ant-select-outlined .ant-select-arrow {
  margin-top: -4px;
}

.ant-select .ant-select-selector .ant-select-selection-item {
  color: #0B3558;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 1;
  /* Firefox */
}

.ant-select .ant-select-selector .ant-select-selection-placeholder {
  color: #0B3558;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 1;
  /* Firefox */
}

.ant-btn.ant-btn-grey span {
  color: #808080;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}
.ant-btn.ant-btn-danger span {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}
.ant-btn-background-ghost.ant-btn-danger.ant-btn span {
  color: #DF0D0D;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.ant-modal-wrap.modal-non-style {
  scrollbar-width: none;
  overflow: hidden;
}

.ant-modal-wrap.modal-non-style::-webkit-scrollbar {
  width: 0px;
}

.ant-modal-wrap.modal-non-style .ant-modal-content {
  padding: 0;
}

.ant-modal-wrap.modal-max-width-80vw .ant-modal {
  max-width: 80vw;
}

.ant-modal-wrap.modal-images {
  background: rgba(0, 0, 0, 0.60);
  backdrop-filter: blur(2px);
}

.ant-modal-wrap.modal-images .ant-modal-content {
  background: transparent;
}

.ant-modal-wrap.modal-images .ant-modal {
  height: 100%;
}

.ant-modal-wrap.modal-images .ant-modal-body {
  position: relative;
}
.ant-select.ant-select-auto-complete .ant-select-selector {
  padding: 0;
  height: 100%;
}
.ant-select.ant-select-auto-complete .ant-select-selector .ant-select-selection-search {
  height: 100%;
}

.ant-notification-notice.ant-notification-notice-success {
  background: #00B466;
  border-radius: 8px;
}
.ant-notification-notice.ant-notification-notice-success .anticon.anticon-close.ant-notification-notice-close-icon,
.ant-notification-notice.ant-notification-notice-success .anticon.anticon-check-circle {
  color: #fff;
}

.ant-notification-notice.ant-notification-notice-success .ant-notification-notice-message {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-bottom: 0 !important;
}

.ant-notification-notice.ant-notification-notice-error {
  background: #DF0D0D;
  border-radius: 8px;
}

.ant-notification-notice.ant-notification-notice-error .anticon.anticon-close.ant-notification-notice-close-icon,
.ant-notification-notice.ant-notification-notice-error .ant-notification-notice-icon-error.anticon {
  color: #fff;
}

.ant-notification-notice.ant-notification-notice-error .ant-notification-notice-message {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-bottom: 0 !important;
}
